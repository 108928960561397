<template>
    <div class="col-sm-12">
      <apexchart :width="Width" :height="Height" type="bar" :options="chartOptions" :series="Series"></apexchart>
    </div>
</template>
<script>
    function chartOptions(){
        return {
            chart: {
                type: 'bar',
                height: 'auto',
                animations: {
                    enabled: true,
                }
            },
            colors: this.Colors,
            xaxis: {
                categories: this.Categories,
             },
            plotOptions: {
              bar: {
                distributed: true,
                borderRadius: 10,
                columnWidth: '40%',
                dataLabels: {
                  position: 'top',
                },
              }
            },
            grid: {
                show: false,
            },
            legend: {
                show: false
            },
            tooltip: {
                enabled: false,
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                }
            },
        }    
    }

     export default {
         name:'SimpleBarShippingLine',
          props: {
             Series: {
                 type: Array,
                 require: true,
                 default: () => [],
             },
             Categories:{
                 type: Array,
                 require: true,
                 default: () => [],
             },
             Colors:{
                type: Array,
                require: false,
                default: () => [],
            },
            Width:{
                type: String,
                require: true,
                default: 'auto',
            },
            Height:{
                type: String,
                require: true,
                default: 'auto',
            },
         },
         methods:{
         },
         computed:{
             chartOptions
         },
     }
</script>