
<script>
import { Chart, registerables } from 'chart.js';
import { HierarchicalScale } from 'chartjs-plugin-hierarchical';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(...registerables);
Chart.register(HierarchicalScale);
Chart.register(ChartDataLabels);
export default {
    name: '',
    type: '',
    props: {
        series: {
            type: Array,
            required: true,
            default: [],
        },
        height:{
            type: String,
            required: false,
            default: '100%',
        },
        labels: {
            type: Array,
            required: true,
            default: [],
        },
        positionLegend:{
            type: String,
            required : false,
            default: () => "bottom"
        },
        typeGraffic:{
            type: String,
            required : false,
            default: () => "bar"
        },
        titleXaxis:{
            type: String,
            required: false,
            default: "",
        },
        bandera:{
            type: Boolean,
            required: true,
            default: false
        },
        colorDatalabels:{
            type: String,
            required: false,
            default: "#000",
        }
    },
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    data () {
        return {
            chart: undefined
        }
    },
    watch: {
        chartConfig () {
            this.updateChart()
        }
    },
    mounted () {
        this.renderChart()
    },
    beforeDestroy () {
        this.destroyChart()
    },
    computed: {
        safeId () {
            // as long as this._uid() works there is no need to generate the key
            const key = () => Math.random().toString(36).replace('0.', '')
            return '__safe_id__chart_bar_horizontal' + (this._uid || key())
        },
        computedHeight() {
            return this.height ? this.height : '300'
        },
        computedtype () {
            return this.typeGraffic
        },
        computedDatasets () {
            return this.series;
        },
        computedLabels () {
            if (this.labels && typeof this.labels !== 'string') {
                return this.labels
            } else if (!this.datasets || !this.datasets[0] || !this.datasets[0].data) {
                return []
            }
            const emptyLabels = Array(this.datasets[0].data.length).fill('')
            if (this.labels === 'indexes') {
                return emptyLabels.map((u, i) => i + 1)
            } else if (this.labels === 'months') {
                return emptyLabels.map((u, i) => this.$options.months[i % 12])
            }
            return emptyLabels
        },
        computedData () {
            return {
                datasets: this.computedDatasets,
                labels: this.computedLabels
            }
        },
        computedOptions () {
            return {
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y',
                plugins: {
                    legend: {
                        position: this.positionLegend,
                    },
                    title: {
                        display: false,
                        text: 'Chart.js Bar Chart'
                    },
                    datalabels: {
                        color: this.colorDatalabels,
                        display: true,
                        formatter: function(value, ctx) {
                            if(value === 0 || value === "0" || value === ""){
                                return ""
                            }else if(value === "0.5" || value === 0.5){
                                return 0;
                            }else{
                                return value;
                            }
                        }
                    },
                },
                scales: {
                    x: {
                        scaleLabel: {
                            display: true,
                            labelString: this.titleXaxis
                        }
                    },
                }
            }
        },
        chartConfig () {
            return {
                type: this.computedtype,
                data: this.computedData,
                options: this.computedOptions,
            }
        }
    },
    methods: {
        renderChart () {
            this.destroyChart()
            this.chart = new Chart(
                this.$refs.canvas.getContext('2d'),
                this.chartConfig
            )
        },
        updateChart () {
            if (this.chart) {
                Object.assign(this.chart, this.chartConfig)
                this.chart.update()
            }
        },
        destroyChart () {
            if (this.chart) {
                this.chart.destroy()
            }
        }
    },
    render(h) {
        return h(
            'div',
            [
                h(
                    'canvas', {
                        attrs: {
                            id: this.safeId,
                        },
                        style: {
                            height: this.height
                        },
                        ref: 'canvas',
                    }
                )
            ]
        )
    }
}
</script>